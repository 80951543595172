import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';

import Layout from '../components/Layout';

import blogStyles from './blog.module.scss';

export default props => {
  // const siteTitle = get(props, 'data.site.siteMetadata.title');
  const posts = get(props, 'data.allContentfulBlogPost.edges');

  return (
    <Layout>
      <Helmet>
        <title>Blog - Replaix</title>
        <link rel="canonical" href="https://replaix.com/blog"/>
        <meta name="description" content="Pleased to present you with the most relevant information for opening escrow and closing your transactions."/>
      </Helmet>
      <Grid container className={blogStyles.container}>
        <Grid item lg={10} style={{ margin: '25px auto' }}>
          <Typography variant="h3" className={blogStyles.blog__title}>Blog</Typography>
          {posts.map(({ node }) => (
            <div className={blogStyles.post} key={node.slug}>
              <Typography variant="h4" className={blogStyles.blog__postTitle}>
                <Link to={`/blog/${node.slug}`}>{node.title}</Link>
              </Typography>
              <div className={blogStyles.authorContainer}>
                <img
                  src={node.author.image.file.url}
                  alt={node.author.name}
                  className={blogStyles.picture}
                  height="48"
                  width="48"
                />
                <div className={blogStyles.infoBlock}>
                  <Typography variant="caption">{node.author.name}</Typography>
                  <Typography variant="caption">{node.createdAt} - {node.readTime} min read</Typography>
                </div>
              </div>
              <Typography
                component="div"
                variant="body2"
                className={'base-font ' + blogStyles.body}
              >
                {documentToReactComponents(node.copy.json)}
              </Typography>
              <div className={blogStyles.authorContainer}>
                <img
                  src={node.author.image.file.url}
                  alt={node.author.name}
                  className={blogStyles.picture}
                  height="72"
                  width="72"
                />
                <div className={blogStyles.infoBlock}>
                  <Typography variant="caption">Written by</Typography>
                  <Typography variant="caption">{node.author.name}</Typography>
                  <Typography variant="caption">{node.author.title}</Typography>
                </div>
              </div>
            </div>
          ))}
        </Grid>
      </Grid>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          title
          author {
            name
            title
            image {
              file {
                url
              }
            }
          }
          readTime
          slug
          createdAt(formatString: "MMMM Do, YYYY")
          description
          copy {
            json
          }
        }
      }
    }
  }
`;